import { ref } from 'vue';
import { Toast, Divider, Dialog } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
export default {
  setup() {
    const onClickLeft = () => history.back();
    const onClickRight = () => Toast('查看更多');
    const onClickAlert = () => {
      Dialog.alert({
        title: '5G服务',
        message: '5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明5G服务说明'
      }).then(() => {
        // on close
      });
    };
    return {
      onClickLeft,
      onClickRight,
      onClickAlert
    };
  }
};